html {
  font-size: 100%;
  height: 100%;
}
body {
  height: 100%;
}
#root {

}
h1, h2, h3 {
  font-family: 'Bebas Neue', sans-serif;
  font-weight: 300;
}
h4, h5, h6 {
  font-family: 'Oswald', sans-serif;
  font-weight: 300;

}
h1 {
  font-size: 3rem;
  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
}
h2 {
  font-size: 2.875rem;
  @media screen and (max-width: 480px) {
    font-size: 1.75rem;
  }
}
h3 {
  font-size: 1.75rem;
  @media screen and (max-width: 480px) {
    font-size: 1.5rem;
  }
}
h4 {
  font-size: 1.5rem;
  @media screen and (max-width: 480px) {
    font-size: 1.25rem;
  }
}

i {
  user-select: none;
}
a {
  text-decoration: none;
}

p, span, a {
  font-family: 'Oswald', sans-serif;
  font-weight: 300;
  color: white;
  font-size: 1.25rem;
}

.container {
  display: flex;
  flex-direction: column;
  height: inherit;
}

.flex-row{
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 768px) {
      flex-direction: column;
  }
}

.rounded-img {
  border-radius: 50%;
}
@-webkit-keyframes wiggle {
  0% {-webkit-transform: rotate(0deg);}
  25% {-webkit-transform: rotate(-3deg);}
  50% {-webkit-transform: rotate(6deg);}
  75% {-webkit-transform: rotate(-3deg);}
  100% {-webkit-transform: rotate(0deg);}
}

@-webkit-keyframes gradient {
  0% {background-position: 0% 50%}
  50% {background-position: 100% 50%}
  100% {background-position: 0% 50%}
}
@-webkit-keyframes expand-logo {
  0% {width: 3rem;}
  100% {width: 4rem;}
}
@-webkit-keyframes diminish-logo {
  0% {width: 4rem;}
  1000% {width: 3rem;}
}
@-webkit-keyframes fade-in {
  0% {opacity: 0;}
  100% {opacity: 1;}
}
@-webkit-keyframes shake {
  0% { -webkit-transform: translate(6px);}
  20% { -webkit-transform: translate(-6px); }
  40% { -webkit-transform: transalate(3px); }
  60% { -webkit-transform: translate(-3px); }
  80% { -webkit-transform: translate(1px); }
  100% { -webkit-transform: translate(0px); }
}
@-webkit-keyframes hover-longer {
  0% { -webkit-transform: translateY(0px) }
  50% { -webkit-transform: translateY(-8px) }
  100% { -webkit-transform: translateY(0px) }
}
@-webkit-keyframes hover {
  0% { -webkit-transform: translateY(0px) }
  50% { -webkit-transform: translateY(-5px) }
  100% { -webkit-transform: translateY(0px) }
}

@-webkit-keyframes loading {
  0% { opacity: 0.3; }
  50% { opacity: 0.9; }
  100% { opacity: 0.3; }
}

@-webkit-keyframes select {
  0% { -webkit-transform: scale(1.05); }
  100% { -webkit-transform: translate(1.02px) }
}

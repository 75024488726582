.game-card-section-enter {
  opacity: 0;
}

.game-card-section-enter-active {
  opacity: 1;
  transition: opacity 400ms;
}

.game-card-section-exit {
  opacity: 1;
}

.game-card-section-exit-active {
  opacity: 0;
  transition: opacity 400ms;
}

/***********************************************/
.button-enter {
  opacity: 0;
  transform: scale(0.9);
}
.button-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.button-exit {
  opacity: 1;

}
.button-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
/***********************************************/

.buttonSlide-enter {
  left: 0;
}
.buttonSlide-enter-active {
  left: -2rem;
}
.buttonSlide-exit {
  left: -2rem;

}
.buttonSlide-exit-active {
  left: 0;

}

/***********************************************/
.slide-up-appear {
  opacity: 0;
  transform: translateY(1rem);
}

.slide-up-appear-done {
  opacity: 1;
  transform: translateY(0rem);
  transition: all 400ms;
}

.slide-up-enter-done {
  opacity: 1;
  transform: translateY(0rem);
  transition: all 400ms;
}

.slide-up-exit {
  opacity: 1;
  transform: translateY(0rem);
}

.slide-up-exit-done {
  opacity: 0;
  transform: translateY(1rem);
  transition: opacity 400ms;
}

/***********************************************/
.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: all 600ms ease-in;
}

